<template>
  <div class="treasury-select-banks-list">
        <draggable-dynamic-table ref="selectBanksList"
                                 :data="data"
                                 :columns="columnsLabel"
                                 :options="options"
                                 :in-modal="true"
                                 @column:select="selectedRows = $event"
                                 @load:more="getBanks()"/>

    <vs-button id="selectBanks" class="useral-action-button" @click="$emit('selected', selectedRows)"/>
  </div>
</template>

<script>
import {getBanks} from '@/http/requests/banks'
import {addComma} from '@/assets/js/functions'
import InsertBank from '@/views/admin/treasury/banks/insert/insertBank'
import axios from "axios";

export default {
  name: 'selectBanks',
  components: {InsertBank},
  props: {
    openingBalance: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedRows: [],
      insertPromptStatus: false,
      loadingTimer: 0,
      selectedBanks: [],
      options: {
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'balance',
          i18n: 'treasury.banks.table.header.balance',
          description: this.$locale.currency(),
          align: 'right',
          width: 'calc(100% / 4)',
          minWidth: 130,
          sortable: true,
          footer: {
            type: 'auto-sum',
            textType: 'price'
          }
        },
        {
          field: 'card_number',
          i18n: 'treasury.banks.table.header.cardNumber',
          width: 'calc(100% / 4)',
          minWidth: 170,
          align: 'center',
          sortable: true
        },
        {
          field: 'account_number',
          i18n: 'treasury.banks.table.header.accountNumber',
          width: 'calc(100% / 4)',
          minWidth: 170,
          align: 'center',
          sortable: true
        },
        {
          field: 'name',
          i18n: 'treasury.banks.table.header.name',
          width: 'calc(100% / 4)',
          minWidth: 170,
          align: 'left',
          ellipsis: true,
          sortable: true,
          locked: true
        },
        {
          field: 'rowNumber',
          i18n: 'treasury.banks.table.header.row',
          width: '60px',
          minWidth: 60,
          align: 'center',
          // sortable: true,
          fixed: true,
          locked: true,
          footer: {}
        },
        {
          field: 'select',
          i18n: 'treasury.banks.table.header.select',
          width: '50px',
          minWidth: 50,
          align: 'center',
          selectable: true,
          fixed: true,
          locked: true
        }
      ],
      data: [],
      page: 1,
      endedList: false
    }
  },
  created () {
    this.getBanks()
  },
  methods: {
    addComma (val) {
      return addComma(val)
    },
    handleClick (id) {
      document.getElementById(id).click()
    },
    getBanks () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (!this.endedList) {
          if (this.$refs.selectBanksList && this.data.length === 0) this.$refs.selectBanksList.loadMoreStatus = 'FirstLoad'
          else if (this.$refs.selectBanksList && this.data.length > 0) this.$refs.selectBanksList.loadMoreStatus = 'Loading'

          getBanks(this.page, {openingInventory: this.openingBalance}).then(response => {
            this.endedList = response.data.data.length === 0
            const banks = response.data.data
            // let banks = this.banks
            banks.forEach((bank) => {
              this.data.push({
                id: bank.id,
                rowNumber: this.data.length + 1,
                name: bank.name,
                account_number: bank.account_number || '',
                card_number: (bank.card_number || '').toString().replace(/\B(?=(\d{4})+(?!\d))/g, '-'),
                balance: {
                  type: 'price',
                  classes: bank.balance && bank.balance < 0 ? 'text-danger' : 'text-success',
                  value: bank.balance ? bank.balance : 0
                }
              })
            })

            this.page = response.data.pagination.current_page + 1
            if (response.data.pagination.current_page === 1) {
              const row_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('rowNumber')
              this.columnsLabel[row_index].footer.value = response.data.pagination.total
            }

            if (this.$refs.selectBanksList) this.$refs.selectBanksList.loadMoreStatus = ''
          }).catch((error) => {
            console.log(error)

            if (this.$refs.selectBanksList && !axios.isCancel(error)) this.$refs.selectBanksList.loadMoreStatus = 'Danger'
          })
        }
      }, 300)
    }
  }
}
</script>

<style lang="scss">
.treasury-select-banks-list {
  height: 100%;

  .after {
    background: none !important;
  }

  button {
    padding: .35rem 2rem !important;
  }

}
</style>
