<template>
  <div class="edit-banks-opening-balance">

    <div class="edit-banks-opening-balance-box">

      <vs-row class="my-2">
        <vs-col class="w-full">
          <custom-price-input :label="`${$t('treasury.banks.openingBalance.edit.labels.price')}`"
                              :is-empty="true"
                              :autocomplete="false"
                              :name="Math.random()"
                              :classes="{'w-full': true}"
                              v-model="updatedBank.price"
                              @pressEnter="sendData"/>
        </vs-col>
      </vs-row>

<!--      <vs-button class="w-full"-->
<!--                 color="danger"-->
<!--                 @click="$refs.deleteOpeningBalanceConfirmation.showDialog()">-->
<!--        {{ $t('treasury.banks.openingBalance.edit.labels.delete') }}-->
<!--      </vs-button>-->
    </div>

    <custom-dialog ref="deleteOpeningBalanceConfirmation"
                   :title="`${$t('treasury.banks.openingBalance.edit.confirmations.delete.title')}`"
                   :body="`${$t('treasury.banks.openingBalance.edit.confirmations.delete.body')}`"
                   @accept="handleDeleteBankOpeningBalance"/>

    <button v-show="false" id="saveBTN" @click="sendData"/>
  </div>
</template>

<script>
import axios from 'axios'
import {addComma} from '@/assets/js/functions'
import CustomIcon from '../../../../../components/customIcon/customIcon'
import {
  deleteBanksOpeningInventories,
  setBanksOpeningInventories
} from '../../../../../http/requests/openingInventories'
import CustomDialog from '../../../../../components/customDialog/customDialog'
import {customParseFloat} from "../../../../../assets/js/functions";
import CustomPriceInput from "../../../../../components/customInput/customPriceInput.vue";

export default {
  name: 'editBanksOpeningBalance',
  components: {CustomPriceInput, CustomDialog, CustomIcon},
  props: {
    bank: {}
  },
  data () {
    return {
      updatedBank: {
        price: {value: ''}
      }
    }
  },
  created () {
    this.updatedBank = JSON.parse(JSON.stringify(this.bank))
    this.updatedBank.price = {
      value: this.bank.price,
      isValid: true
    }
  },
  methods: {
    customParseFloat,
    sendData () {
      if ((this.updatedBank.price.value || '0') === '0') {
        this.handleDeleteBankOpeningBalance()
        return false
      }

      const bank = {
        id: this.updatedBank.id,
        price: customParseFloat(this.updatedBank.price.value.toString().replaceAll(',', ''))
      }
      setBanksOpeningInventories([bank]).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('treasury.banks.openingBalance.notifications.edit.success'),
          color: 'success',
          time: 2400,
          icon: 'icon-check',
          iconPack: 'feather'
        })

        this.$store.dispatch('helper/changeOpeningBalance')
      }).catch((error) => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
            text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('treasury.banks.openingBalance.notifications.edit.error'),
            color: 'danger',
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        }
      })
    },
    handleDeleteBankOpeningBalance () {
      if (this.bank && this.bank.id) {
        deleteBanksOpeningInventories(this.bank.id).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('treasury.banks.openingBalance.notifications.delete.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather'
          })

          this.$store.dispatch('helper/deleteOpeningBalance')
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('treasury.banks.openingBalance.notifications.delete.error'),
              color: 'danger',
              time: 2400,
              icon: 'icon-alert-circle',
              iconPack: 'feather'
            })
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
.select-file-input {
  width: 100%;
  background: #28c76f;
  display: block;
  padding: 10px;
  border-radius: .5rem;

  span {
    color: white !important;

    div.icon {
      background-color: white !important;
    }
  }

  input {
    display: none;
  }
}
</style>
