<template>
  <div class="update-account">
    <custom-validate-input :label="$t('accountancy.types.labels.name')"
                           :is-empty="false"
                           :rtl="true"
                           align="right"
                           :classes="{'w-full': true}"
                           v-model="account.name"/>

    <button v-show="false" id="saveBTN" @click="sendData"/>
  </div>
</template>

<script>
import CustomValidateInput from '../../../../../components/customInput/customValidateInput'
import {editAccount} from '../../../../../http/requests/accountancy'
import axios from 'axios'

export default {
  name: 'editAccount',
  components: {CustomValidateInput},
  props: {
    accountData: {}
  },
  data () {
    return {
      account: {
        name: {}
      }
    }
  },
  created () {
    if (this.accountData !== {}) {
      this.account = {
        id: this.accountData.id,
        name: {
          value: this.accountData.label,
          isValid: true
        }
      }
    }
  },
  methods: {
    sendData () {
      const account = {
        name: this.account.name.value
      }

      if (!account.name.length) {
        this.$vs.notify({
          title: this.$t('alert.error.title'),
          text: this.$t('accountancy.types.notifications.parseError.name'),
          time: 2400,
          color: 'danger',
          icon: 'icon-alert-circle',
          iconPack: 'feather'
        })
      }

      editAccount(this.account.id, account).then(() => {
        this.$vs.notify({
          title: this.$t('alert.message.title'),
          text: this.$t('accountancy.types.notifications.edit.success'),
          time: 2400,
          color: 'success',
          icon: 'icon-check',
          iconPack: 'feather'
        })
        this.$emit('account:updated')
        this.$store.dispatch('helper/changeAccount')
      }).catch(error => {
        if (axios.isCancel(error)) {
          this.$vs.notify({
            title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('accountancy.types.notifications.edit.error'),
            time: 2400,
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
