<template>
  <div class="users-opening-balance-box">
    <draggable-dynamic-table ref="usersOpeningBalanceList"
                             :in-modal="true"
                             :fix-screen="true"
                             :active-grid="true"
                             :options="options"
                             :columns="columnsLabel"
                             @row:clicked="handleRowClicked($event)"
                             @load:more="getUsersOpeningBalance"
                             v-model="data"/>

    <!-- insert new user prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="newSuggestUserPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertUserBTN')">
              <custom-icon icon="CHECK" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('users.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="newSuggestUserPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <insert-user :in-modal="true" @inserted="handleNewUserInserted"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- insert users opening balance prompt -->
    <vs-prompt
      class="big-prompt fixed-height p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="insertOpeningInventoryPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('saveBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
             {{ $t('users.openingBalance.insert.title', {type: type === 'debtor' ? this.$t('users.openingBalance.type.debtor') : this.$t('users.openingBalance.type.creditor')}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer flex">
            <div class="mx-2" @click="handleClick('importDataBTN')">
              <custom-icon icon="IMPORT" color="primary"/>
            </div>
            <div @click="insertOpeningInventoryPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <insert-users-opening-balance :type="type"/>
      </div>

    </vs-prompt>

    <!-- edit users opening balance prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editOpeningInventoryPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('saveBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('users.openingBalance.edit.title', {user: selectedUser.name}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editOpeningInventoryPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <edit-users-opening-balance :type="type" :user="selectedUser"/>
      </div>

    </vs-prompt>

    <button id="InsertOpeningInventoryBTN" class="useral-action-button"
            @click="insertOpeningInventoryPromptStatus = true"></button>
  </div>
</template>

<script>
import axios from 'axios'
import {
  getCreditorUsersOpeningInventories,
  getDebtorUsersOpeningInventories
} from '@/http/requests/openingInventories'
import {addComma} from '@/assets/js/functions'
import SelectUsers from '@/views/admin/users/select/selectUsers'
import InsertUser from '@/views/admin/users/insert/insertUser'
import InsertUsersOpeningBalance from './insertUsersOpeningBalance'
import {
  deleteCreditorUsersOpeningInventories,
  deleteDebtorUsersOpeningInventories, getCreditorUserOpeningInventories, getDebtorUserOpeningInventories
} from '../../../../http/requests/openingInventories'
import CustomIcon from '../../../../components/customIcon/customIcon'
import EditUsersOpeningBalance from './editUsersOpeningBalance'
import {convertTableFilterDate, convertUtcToLocale, customParseFloat} from "../../../../assets/js/functions";
import {getUsers} from "../../../../http/requests/users/users";

export default {
  name: 'usersOpeningBalance',
  components: {EditUsersOpeningBalance, CustomIcon, InsertUsersOpeningBalance, InsertUser, SelectUsers},
  data () {
    return {
      newSuggestUserPromptStatus: false,
      insertOpeningInventoryPromptStatus: false,
      editOpeningInventoryPromptStatus: false,
      page: 1,
      endedList: false,
      loadingTimer: 0,
      options: {
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'price',
          i18n: 'users.openingBalance.list.table.header.price',
          description: this.$locale.currency(),
          width: '50%',
          minWidth: 200,
          /*sortable: true,*/
          // editable: true,
          // returnValue: true,
          // valueType: 'price',
          align: 'right',
          footer: {
            type: 'auto-sum',
            textType: 'price'
          }
        },
        {
          field: 'name',
          i18n: 'users.openingBalance.list.table.header.name',
          align: 'left',
          width: '50%',
          minWidth: 200
        },
        {
          field: 'rowNumber',
          i18n: 'users.openingBalance.list.table.header.rowNumber',
          align: 'center',
          width: '70px',
          minWidth: 70,
          fixed: true,
          // sortable: true,
          footer: {}
        }
      ],
      data: [],
      selectedUser: {}
    }
  },
  created () {
    this.getUsersOpeningBalance()
    this.checkOpeningBalanceStatus()
  },
  props: {
    type: {
      type: String
    }
  },
  methods: {
    getUsersOpeningBalance () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (!this.endedList) {
          if (this.$refs.usersOpeningBalanceList && this.data.length === 0) this.$refs.usersOpeningBalanceList.loadMoreStatus = 'FirstLoad'
          else if (this.$refs.usersOpeningBalanceList && this.data.length > 0) this.$refs.usersOpeningBalanceList.loadMoreStatus = 'Loading'

          if (this.type === 'debtor') {
            getDebtorUsersOpeningInventories(this.page).then(response => {
              const users = response.data.data

              this.total_count = response.data.pagination.total

              users.forEach((user) => {
                this.data.push({
                  rowNumber: this.total_count - this.data.length,
                  id: user.id,
                  name: `${user.name} ${user.family}`,
                  price: addComma(user.price),
                  flag: 'SAVED'
                })
              })

              this.page = response.data.pagination.current_page + 1
              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('rowNumber')
                this.columnsLabel[row_index].footer = {value: response.data.pagination.total}
              }

              if (this.$refs.usersOpeningBalanceList) this.$refs.usersOpeningBalanceList.loadMoreStatus = ''
            }).catch((error) => {
              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
                  text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              } else {

                if (this.$refs.usersOpeningBalanceList && !axios.isCancel(error)) this.$refs.usersOpeningBalanceList.loadMoreStatus = 'Danger'
              }
            })
          } else {
            getCreditorUsersOpeningInventories(this.page).then(response => {
              const users = response.data.data

              this.total_count = response.data.pagination.total

              users.forEach((user) => {
                this.data.push({
                  rowNumber: this.total_count - this.data.length,
                  id: user.id,
                  name: `${user.name} ${user.family}`,
                  price: addComma(user.price < 0 ? user.price * -1 : user.price),
                  flag: 'SAVED'
                })
              })

              this.page = response.data.pagination.current_page + 1
              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('rowNumber')
                this.columnsLabel[row_index].footer = {value: response.data.pagination.total}
              }

              if (this.$refs.usersOpeningBalanceList) this.$refs.usersOpeningBalanceList.loadMoreStatus = ''
            }).catch((error) => {
              console.log(error)
              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
                  text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              } else {

                if (this.$refs.usersOpeningBalanceList && !axios.isCancel(error)) this.$refs.usersOpeningBalanceList.loadMoreStatus = 'Danger'
              }
            })
          }
        }
      }, 300)
    },
    handleDeleteRow (row) {
      if (row) {
        const index = this.data.map((elm) => {
          return elm.id
        }).indexOf(row.id)
        if (index > -1 && row.id !== 0) {

          if (this.type === 'debtor') {
            deleteDebtorUsersOpeningInventories(row.id).then(() => {
              this.$vs.notify({
                title: 'پیغام',
                text: 'اول دوره بدهکاران به شرکت با موفقیت حذف شد',
                color: 'success',
                time: 2400,
                icon: 'icon-check',
                iconPack: 'feather'
              })

              this.data.splice(index, 1)
              this.$store.dispatch('helper/changeOpeningBalance')
            }).catch((error) => {
              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              } else {
                this.$vs.notify({
                  title: 'پیغام',
                  text: 'حذف اول دوره بدهکاران به شرکت با خطا همراه شد',
                  color: 'danger',
                  time: 2400,
                  icon: 'icon-alert-circle',
                  iconPack: 'feather'
                })
              }
            })
          } else {
            deleteCreditorUsersOpeningInventories(row.id).then(() => {
              this.$vs.notify({
                title: 'پیغام',
                text: 'اول دوره بستانداران از شرکت با موفقیت حذف شد',
                color: 'success',
                time: 2400,
                icon: 'icon-check',
                iconPack: 'feather'
              })

              this.data.splice(index, 1)
              this.$store.dispatch('helper/changeOpeningBalance')
            }).catch((error) => {
              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              } else {
                this.$vs.notify({
                  title: 'پیغام',
                  text: 'حذف اول دوره بستانکاران از شرکت با خطا همراه شد',
                  color: 'danger',
                  time: 2400,
                  icon: 'icon-alert-circle',
                  iconPack: 'feather'
                })
              }
            })
          }
        }
      }
    },
    handleRowClicked (item) {
      if (!this.$store.state.helper.openingBalanceLocked) {
        this.selectedUser = item
        this.editOpeningInventoryPromptStatus = true
      }
    },
    handleClick (id) {
      document.getElementById(id).click()
    },
    checkOpeningBalanceStatus () {
      if (this.$store.state.helper.openingBalanceLocked) {
        const mapper = this.columnsLabel.map(elm => {
          return elm.field
        })

        const name_index = mapper.indexOf('name')
        this.columnsLabel[name_index].editable = false
        this.columnsLabel[name_index].suggest = false
        this.columnsLabel[name_index].suggestPrompt = false

        const price_index = mapper.indexOf('price')
        this.columnsLabel[price_index].editable = false

        const action_index = mapper.indexOf('delete')
        this.columnsLabel.splice(action_index, 1)
      }
    },
    refreshUserDetails () {
      if (this.type === 'debtor') {
        getDebtorUserOpeningInventories(this.selectedUser.id).then(response => {
          const user = response.data.data
          const user_index = this.data.map(elm => {
            return elm.id
          }).indexOf(user.id)

          if (user_index >= 0) {
            this.data[user_index] = {
              rowNumber: this.data[user_index].rowNumber,
              id: user.id,
              name: `${user.name} ${user.family}`,
              price: addComma(user.price),
              flag: 'SAVED'
            }

            if (this.$refs.usersOpeningBalanceList) this.$refs.usersOpeningBalanceList.setTableData()
          }
        })
      } else {
        getCreditorUserOpeningInventories(this.selectedUser.id).then(response => {
          const user = response.data.data
          const user_index = this.data.map(elm => {
            return elm.id
          }).indexOf(user.id)

          if (user_index >= 0) {
            this.data[user_index] = {
              rowNumber: this.data[user_index].rowNumber,
              id: user.id,
              name: `${user.name} ${user.family}`,
              price: addComma(user.price < 0 ? user.price * -1 : user.price),
              flag: 'SAVED'
            }

            if (this.$refs.usersOpeningBalanceList) this.$refs.usersOpeningBalanceList.setTableData()
          }
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            }
          })
      }
    },
    deleteUserDetails () {
      const user_index = this.data.map(elm => {
        return elm.id
      }).indexOf(this.selectedUser.id)
      if (user_index >= 0) this.data.splice(user_index, 1)

      if (this.type === 'debtor') {
        getDebtorUsersOpeningInventories(1).then(response => {
          if (response.data.pagination.current_page === 1) {
            const row_index = this.columnsLabel.map((e) => {
              return e.field
            }).indexOf('rowNumber')
            this.columnsLabel[row_index].footer = {value: response.data.pagination.total}
          }
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            }
          })
      } else {
        getCreditorUsersOpeningInventories(1).then(response => {
          if (response.data.pagination.current_page === 1) {
            const row_index = this.columnsLabel.map((e) => {
              return e.field
            }).indexOf('rowNumber')
            this.columnsLabel[row_index].footer = {value: response.data.pagination.total}
          }
        })
          .catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            }
          })
      }

      if (this.$refs.usersOpeningBalanceList) this.$refs.usersOpeningBalanceList.setTableData()
    },
    handleNewUserInserted (user) {
      this.newSuggestUserPromptStatus = false
      const insertedUser = {
        rowNumber: this.data.length + 1,
        id: user.id,
        name: ` ${user.name} ${user.family}`,
        price: '0',
        flag: 'new'
      }
      this.data.push(insertedUser)
      this.selectedUser = insertedUser
      this.editOpeningInventoryPromptStatus = true
      const row_index = this.columnsLabel.map((e) => {
        return e.field
      }).indexOf('rowNumber')
      this.columnsLabel[row_index].footer = {value: this.data.length}
    }
  },
  watch: {
    '$store.state.helper.openingBalanceChanged': {
      handler (val) {
        if (val) {
          if (this.insertOpeningInventoryPromptStatus) {
            this.page = 1
            this.data = []
            this.$nextTick(() => {
              this.getUsersOpeningBalance()
            })
          }

          if (this.editOpeningInventoryPromptStatus && this.selectedUser) this.refreshUserDetails()

          this.insertOpeningInventoryPromptStatus = false
          this.editOpeningInventoryPromptStatus = false
        }
      },
      deep: true
    },
    '$store.state.helper.openingBalanceDeleted': {
      handler (val) {
        if (val) {
          if (this.editOpeningInventoryPromptStatus && this.selectedUser) this.deleteUserDetails()
          this.editOpeningInventoryPromptStatus = false
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.users-opening-balance-box {
  height: 100%;
}
</style>
