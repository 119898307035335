<template>
  <div class="banks-opening-balance-box">
    <draggable-dynamic-table ref="banksOpeningBalanceList"
                             :in-modal="true"
                             :fix-screen="true"
                             :active-grid="true"
                             :options="options"
                             :columns="columnsLabel"
                             @row:clicked="handleRowClicked($event)"
                             @filter:remove="setFilter($event)"
                             @filter:set="setFilter($event)"
                             @sort:set="setSort($event)"
                             @load:more="getBanks"
                             v-model="data"/>

    <!-- insert new bank prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="newSuggestBankPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertBankBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.banks.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="newSuggestBankPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <insert-bank class="p-2" :in-modal="true" @inserted="handleInsertNewBank($event)"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <!-- insert banks opening balance prompt -->
    <vs-prompt
      class="big-prompt fixed-height p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="insertOpeningInventoryPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('saveBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.banks.openingBalance.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="insertOpeningInventoryPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <insert-banks-opening-balance/>
      </div>

    </vs-prompt>

    <!-- edit banks opening balance prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editOpeningInventoryPromptStatus">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('saveBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('treasury.banks.openingBalance.edit.title', {bank: this.selectedBank.name}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editOpeningInventoryPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <edit-banks-opening-balance :bank="selectedBank"/>
      </div>

    </vs-prompt>

    <button id="InsertOpeningInventoryBTN"
            @click="newSuggestBankPromptStatus = true"
            v-show="false"/>
  </div>
</template>

<script>
import axios from 'axios'
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import SelectBanks from '@/views/admin/treasury/banks/select/selectBanks'
import {getBanksOpeningInventories} from '@/http/requests/openingInventories'
import {addComma} from '@/assets/js/functions'
import InsertBank from '@/views/admin/treasury/banks/insert/insertBank'
import InsertBanksOpeningBalance from './insertBanksOpeningBalance'
import {
  deleteBanksOpeningInventories, getBankOpeningInventories
} from '../../../../../http/requests/openingInventories'
import EditBanksOpeningBalance from './editBanksOpeningBalance'
import CustomIcon from '../../../../../components/customIcon/customIcon'
import {getBanks} from "../../../../../http/requests/banks";

export default {
  name: 'banksOpeningBalance',
  components: {
    CustomIcon,
    EditBanksOpeningBalance,
    InsertBanksOpeningBalance,
    InsertBank,
    SelectBanks,
    DraggableDynamicTable
  },
  data () {
    return {
      newSuggestBankPromptStatus: false,
      insertOpeningInventoryPromptStatus: false,
      editOpeningInventoryPromptStatus: false,
      selectedBank: {},
      filters: {},
      sorts: [],
      page: 1,
      endedList: false,
      loadingTimer: 0,
      options: {
        rowKeyField: 'id'
      },
      columnsLabel: [
        /*{
          field: 'delete',
          headerName: 'حذف',
          width: '70px',
          minWidth: 70,
          /!*sortable: true,*!/
          actions: true,
          showAction: 'always',
          action: {
            color: 'danger',
            icon: 'TRASH',
            iconPack: 'useral',
            type: 'button'
          },
          event: 'row:deleted'
        },*/
        {
          field: 'price',
          i18n: 'treasury.banks.openingBalance.table.header.price',
          description: this.$locale.currency(),
          width: 'calc(100% / 2)',
          minWidth: 200,
          align: 'right',
          footer: {
            type: 'auto-sum',
            textType: 'price'
          }
        },
        {
          field: 'name',
          i18n: 'treasury.banks.openingBalance.table.header.name',
          align: 'left',
          ellipsis: true,
          width: 'calc(100% / 2)',
          minWidth: 200,
          sortable: true,
          filter: false,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'rowNumber',
          i18n: 'treasury.banks.openingBalance.table.header.row',
          align: 'center',
          width: '70px',
          minWidth: 70,
          fixed: true,
          footer: {}
        }
      ],
      openingBalanceData: [],
      data: []
    }
  },
  created () {
    this.getBanksOpeningBalance()
    this.checkOpeningBalanceStatus()
  },
  methods: {
    getBanksOpeningBalance () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (!this.endedList) {
          // if (this.$refs.banksOpeningBalanceList && this.data.length === 0) this.$refs.banksOpeningBalanceList.loadMoreStatus = 'FirstLoad'
          // else if (this.$refs.banksOpeningBalanceList && this.data.length > 0) this.$refs.banksOpeningBalanceList.loadMoreStatus = 'Loading'

          getBanksOpeningInventories(this.page).then(response => {
            const banks = response.data.data

            this.total_count = response.data.pagination.total

            banks.forEach((bank) => {
              this.openingBalanceData.push({
                rowNumber: this.total_count - this.openingBalanceData.length,
                id: bank.id,
                name: bank.name,
                price: addComma(bank.price),
                flag: 'SAVED'
              })
            })
            this.getBanks()

            // if (this.$refs.banksOpeningBalanceList) this.$refs.banksOpeningBalanceList.loadMoreStatus = ''
          }).catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {

              // if (this.$refs.banksOpeningBalanceList) this.$refs.banksOpeningBalanceList.loadMoreStatus = 'Danger'
            }
          })
        }
      }, 300)
    },
    getBanks () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (!this.endedList) {
          if (this.$refs.banksOpeningBalanceList && this.data.length === 0) this.$refs.banksOpeningBalanceList.loadMoreStatus = 'FirstLoad'
          else if (this.$refs.banksOpeningBalanceList && this.data.length > 0) this.$refs.banksOpeningBalanceList.loadMoreStatus = 'Loading'

          getBanks(this.page, this.filters, this.sorts).then(response => {
            this.endedList = response.data.data.length === 0
            const banks = response.data.data
            banks.forEach((bank) => {
              let price = 0
              const bankIndex = this.openingBalanceData.map(elm => elm.id).indexOf(bank.id)
              if (bankIndex > -1) {
                price = this.openingBalanceData[bankIndex].price || 0
              }
              this.data.push({
                rowNumber: this.data.length + 1,
                id: bank.id,
                name: bank.name,
                price: price,
                flag: bankIndex === -1 ? 'new' : 'edit'
              })
            })

            this.page = response.data.pagination.current_page + 1
            if (response.data.pagination.current_page === 1) {
              const row_index = this.columnsLabel.map((e) => {
                return e.field
              }).indexOf('rowNumber')
              this.columnsLabel[row_index].footer.value = response.data.pagination.total
            }

            if (this.$refs.banksOpeningBalanceList) this.$refs.banksOpeningBalanceList.loadMoreStatus = ''
          }).catch(error => {
            if (!axios.isCancel(error) &&this.$refs.banksOpeningBalanceList) this.$refs.banksOpeningBalanceList.loadMoreStatus = 'Danger'
          })
        }
      }, 300)
    },
    setFilter (filters, remove) {
      this.selectedFilters = filters
      const filters_list = {}
      Object.keys(filters).forEach((key) => {
        switch (key) {

          case 'name':
            if (filters[key].search !== '') filters_list.name = filters[key].search
            break

          case 'account_number':
            if (filters[key].search !== '') filters_list.account_number = filters[key].search
            break

          case 'card_number':
            if (filters[key].search !== '') filters_list.card_number = filters[key].search
            break

          case 'balance':
            if (filters[key].search !== '') filters_list.balance = `${  filters[key].search  },${  filters[key].type.id}`
            break
        }
      })

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.$nextTick(() => {
        this.getBanks()
      })
    },
    setSort (sorts) {
      const sorts_list = []
      Object.keys(sorts).forEach((key) => {
        switch (key) {

          case 'rowNumber':
            sorts_list.push(`order[0]=id,${  sorts[key] ? 'desc' : 'asc'}`)
            break

          case 'name':
            sorts_list.push(`order[0]=name,${  sorts[key] ? 'desc' : 'asc'}`)
            break

          case 'account_number':
            sorts_list.push(`order[0]=account_number,${  sorts[key] ? 'desc' : 'asc'}`)
            break

          case 'card_number':
            sorts_list.push(`order[0]=card_number,${  sorts[key] ? 'desc' : 'asc'}`)
            break

          case 'balance':
            sorts_list.push(`order[0]=virtual_balance,${  sorts[key] ? 'desc' : 'asc'}`)
            break
        }
      })

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.$nextTick(() => {
        this.getBanks()
      })
    },
    handleDeleteRow (row) {
      if (row) {
        const index = this.data.map((elm) => {
          return elm.id
        }).indexOf(row.id)
        if (index > -1 && row.id !== 0) {
          deleteBanksOpeningInventories(row.id).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('treasury.banks.openingBalance.notifications.delete.success'),
              color: 'success',
              time: 2400,
              icon: 'icon-check',
              iconPack: 'feather'
            })

            this.data.splice(index, 1)
            this.$store.dispatch('helper/changeOpeningBalance')
          }).catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('treasury.banks.openingBalance.notifications.delete.error'),
                color: 'danger',
                time: 2400,
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
            }
          })
        }
      }
    },
    handleRowClicked (row) {
      if (!this.$store.state.helper.openingBalanceLocked) {
        this.selectedBank = row
        this.editOpeningInventoryPromptStatus = true
      }
    },
    handleClick (id) {
      document.getElementById(id).click()
    },
    checkOpeningBalanceStatus () {
      if (this.$store.state.helper.openingBalanceLocked) {
        const mapper = this.columnsLabel.map(elm => {
          return elm.field
        })

        const name_index = mapper.indexOf('name')
        this.columnsLabel[name_index].editable = false

        const price_index = mapper.indexOf('price')
        this.columnsLabel[price_index].editable = false

        const action_index = mapper.indexOf('delete')
        this.columnsLabel.splice(action_index, 1)
      }
    },
    refreshBankDetails () {
      getBankOpeningInventories(this.selectedBank.id).then(response => {
        const bank = response.data.data
        const bank_index = this.data.map(elm => { return elm.id }).indexOf(bank.id)
        if (bank_index >= 0) {
          this.data[bank_index] = {
            rowNumber: this.data[bank_index].rowNumber,
            id: bank.id,
            name: bank.name,
            price: addComma(bank.price),
            flag: 'SAVED'
          }

          if (this.$refs.banksOpeningBalanceList) this.$refs.banksOpeningBalanceList.setTableData()
        }
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })
    },
    deleteBankDetails () {
      const bank_index = this.data.map(elm => { return elm.id }).indexOf(this.selectedBank.id)
      if (bank_index >= 0) this.data[bank_index].price = '0'

      getBanksOpeningInventories(1).then(response => {
        // if (response.data.pagination.current_page === 1) {
        //   const row_index = this.columnsLabel.map((e) => {
        //     return e.field
        //   }).indexOf('rowNumber')
        //   this.columnsLabel[row_index].footer = {value: response.data.pagination.total}
        // }
      })
        .catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          }
        })

      if (this.$refs.banksOpeningBalanceList) this.$refs.banksOpeningBalanceList.setTableData()
    },
    handleInsertNewBank (bank) {
      this.newSuggestBankPromptStatus = false
      const insertedBank = {
        rowNumber: this.data.length + 1,
        id: bank.id,
        name: bank.name,
        price: '0',
        flag: 'new'
      }
      this.data.push(insertedBank)
      this.selectedBank = insertedBank
      this.editOpeningInventoryPromptStatus = true
      const row_index = this.columnsLabel.map((e) => {
        return e.field
      }).indexOf('rowNumber')
      this.columnsLabel[row_index].footer = {value: this.data.length}
    }
  },
  watch: {
    '$store.state.helper.openingBalanceChanged': {
      handler (val) {
        if (val) {
          if (this.insertOpeningInventoryPromptStatus) {
            this.page = 1
            this.data = []
            this.$nextTick(() => {
              this.getBanksOpeningBalance()
            })
          }

          if (this.editOpeningInventoryPromptStatus && this.selectedBank) this.refreshBankDetails()

          this.insertOpeningInventoryPromptStatus = false
          this.editOpeningInventoryPromptStatus = false
        }
      },
      deep: true
    },
    '$store.state.helper.openingBalanceDeleted': {
      handler (val) {
        if (val) {
          if (this.editOpeningInventoryPromptStatus && this.selectedBank) this.deleteBankDetails()
          this.editOpeningInventoryPromptStatus = false
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.banks-opening-balance-box {
  height: 100%;
}
</style>
