/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store'
import {afterEach} from '@/router/afterEach'
import {beforeEach} from '@/router/beforeEach'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [

    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: '',
      component: () => import('../layouts/main/Main.vue'),
      meta: {requiresAuth: true},
      children: [
        // =============================================================================
        // HOME Routes
        // =============================================================================
        {
          path: '/',
          name: 'home',
          component: () => import(/* webpackChunkName: "home" */ '../views/admin/Home.vue'),
          meta: {
            pageTitle: 'داشبورد',
            pageTitleI18n: 'dashboard'
          }
        },

        // =============================================================================
        // PROFILE PAGES LAYOUTS
        // =============================================================================
        {
          path: '/profile',
          component: () => import('../views/admin/profile/index.vue'),
          children: [
            {
              path: '/',
              component: () => import(/* webpackChunkName: "profile" */ '../views/admin/profile/profile/userProfile.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.title',
                layoutStatus: 'profile'
                // rule: 'editor'
              },
              name: 'Profile'
            },
            {
              path: 'transactions',
              name: 'profileTransactions',
              component: () => import(/* webpackChunkName: "profileTransactions" */ '../views/admin/profile/transactions/profileTransactions.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.transactions.title'
                // rule: 'editor'
              }
            },
            {
              path: 'addresses',
              name: 'profileAddresses',
              component: () => import(/* webpackChunkName: "profileAddresses" */ '../views/admin/profile/addresses/profileAddresses.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.address.title'
                // rule: 'editor'
              }
            },
            {
              path: 'sessions',
              name: 'profileActiveSessions',
              component: () => import(/* webpackChunkName: "profileAddresses" */ '../views/admin/profile/activeSessions/profileActiveSessions.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.activeSessions.title'
                // rule: 'editor'
              }
            },
            {
              path: 'received-events',
              name: 'profileReceivedEvents',
              component: () => import(/* webpackChunkName: "profileReceivedEvents" */ '../views/admin/profile/events/profileEvents.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.events.receive.title'
                // rule: 'editor'
              }
            },
            {
              path: 'sent-events',
              name: 'profileSentEvents',
              component: () => import(/* webpackChunkName: "profileSentEvents" */ '../views/admin/profile/events/profileEvents.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.events.send.title'
                // rule: 'editor'
              }
            },
            {
              path: 'customize',
              name: 'profileCustomize',
              component: () => import(/* webpackChunkName: "profileCustomize" */ '../views/admin/profile/customize/profileCustomize.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.customize.title'
                // rule: 'editor'
              }
            },
            {
              path: 'edit',
              name: 'editProfile',
              component: () => import(/* webpackChunkName: "editProfile" */ '../views/admin/profile/edit/editProfile.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'profile.edit.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // EVENTS PAGES LAYOUTS
        // =============================================================================
        {
          path: '/events',
          component: () => import('../views/admin/events/index.vue'),
          children: [
            {
              path: '/',
              name: 'events',
              component: () => import(/* webpackChunkName: "events" */ '../views/admin/events/list/events.vue'),
              meta: {
                permission: 'event.show',
                pageTitle: '',
                pageTitleI18n: 'events.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // USERS PAGES LAYOUTS
        // =============================================================================
        {
          path: '/users',
          component: () => import('../views/admin/users/index.vue'),
          children: [
            {
              path: '/',
              name: 'users',
              component: () => import(/* webpackChunkName: "users" */ '../views/admin/users/list/usersList.vue'),
              meta: {
                permission: 'user.show',
                pageTitle: 'اشخاص',
                pageTitleI18n: 'users.list.title'
                // rule: 'editor'
              }
            },
            {
              path: 'trash',
              name: 'usersTrash',
              component: () => import(/* webpackChunkName: "usersTrash" */ '../views/admin/users/trash/usersTrash.vue'),
              meta: {
                permission: 'user.delete',
                pageTitle: 'سطل زباله اشخاص',
                pageTitleI18n: 'users.trash.title'
                // rule: 'editor'
              }
            },
            {
              path: 'insert',
              name: 'insertUser',
              component: () => import(/* webpackChunkName: "insertUser" */ '../views/admin/users/insert/insertUser.vue'),
              meta: {
                permission: 'user.create',
                pageTitle: 'افزودن شخص',
                pageTitleI18n: 'users.insert.title'
                // rule: 'editor'
              }
            },
            {
              path: ':id',
              component: () => import('../views/admin/users/user/index.vue'),
              children: [
                {
                  path: '/',
                  component: () => import(/* webpackChunkName: "user" */ '../views/admin/users/user/profile/userProfile'),
                  name: 'user',
                  meta: {
                    permission: 'user.show',
                    pageTitle: 'شخص',
                    pageTitleI18n: 'users.profile.title',
                    layoutStatus: 'profile'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'edit',
                  name: 'editUser',
                  component: () => import(/* webpackChunkName: "editUser" */ '../views/admin/users/edit/editUser.vue'),
                  meta: {
                    permission: 'user.update',
                    pageTitle: ',ویرایش اطلاعات شخص',
                    pageTitleI18n: 'users.edit.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'transactions',
                  name: 'userTransactions',
                  component: () => import(/* webpackChunkName: "userTransactions" */ '../views/admin/users/user/transactions/userTransactions.vue'),
                  meta: {
                    permission: 'user.show',
                    pageTitle: 'گردش مالی شخص',
                    pageTitleI18n: 'users.transactions.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'max-debt',
                  name: 'userMaxDebt',
                  component: () => import(/* webpackChunkName: "userTransactions" */ '../views/admin/users/user/maxDebt/setUserMaxDebt.vue'),
                  meta: {
                    permission: 'user.show',
                    pageTitle: 'گردش مالی شخص',
                    pageTitleI18n: 'users.transactions.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'transactions/:docId',
                  name: 'userTransaction',
                  component: () => import(/* webpackChunkName: "userTransaction" */ '../views/admin/accountancy/documents/document/document.vue'),
                  meta: {
                    permission: 'user.show',
                    pageTitle: 'سند گردش مالی شخص',
                    pageTitleI18n: 'users.transactions.documentTitle'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'addresses',
                  name: 'userAddresses',
                  component: () => import(/* webpackChunkName: "userAddresses" */ '../views/admin/users/user/addresses/userAddresses.vue'),
                  meta: {
                    permission: 'user.show',
                    pageTitle: 'شخص',
                    pageTitleI18n: 'users.addresses.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'activities-log',
                  name: 'userActivitiesLog',
                  component: () => import(/* webpackChunkName: "userActivitiesLog" */ '../views/admin/users/user/activitiesLog/userActivitiesLog.vue'),
                  meta: {
                    permission: 'activity_logs.show',
                    pageTitle: 'لاگ های شخص',
                    pageTitleI18n: 'users.logs.title'
                    // rule: 'editor'
                  }
                }
              ]
            }
          ]
        },

        // =============================================================================
        // BOOKING PAGES LAYOUTS
        // =============================================================================
        {
          path: '/booking',
          component: () => import('../views/admin/booking/index.vue'),
          children: [
            {
              path: '/',
              redirect: '/error-404'
            },
            {
              path: 'clubs',
              component: () => import('../views/admin/booking/clubs/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'clubs',
                  component: () => import(/* webpackChunkName: "clubs" */ '../views/admin/booking/clubs/list/clubsList.vue'),
                  meta: {
                    permission: 'club.show',
                    pageTitle: 'باشگاه ها',
                    pageTitleI18n: 'clubs.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'clubsTrash',
                  component: () => import(/* webpackChunkName: "clubsTrash" */ '../views/admin/booking/clubs/trash/clubsTrash.vue'),
                  meta: {
                    permission: 'club.delete',
                    pageTitle: 'سطل زباله باشگاه',
                    pageTitleI18n: 'clubs.trash.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  component: () => import('../views/admin/booking/clubs/club/index.vue'),
                  children: [
                    {
                      path: '/',
                      component: () => import(/* webpackChunkName: "club" */ '../views/admin/booking/clubs/club/profile/clubProfile.vue'),
                      name: 'club',
                      meta: {
                        permission: 'club.show',
                        pageTitle: 'باشگاه',
                        pageTitleI18n: 'clubs.profile.title',
                        layoutStatus: 'profile'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'edit',
                      component: () => import(/* webpackChunkName: "editClub" */ '../views/admin/booking/clubs/edit/editClub.vue'),
                      name: 'editClub',
                      meta: {
                        permission: 'club.update',
                        pageTitle: 'ویرایش باشگاه',
                        pageTitleI18n: 'clubs.edit.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'calendar',
                      component: () => import(/* webpackChunkName: "clubCalendar" */ '../views/admin/booking/clubs/club/calendar/clubCalendar.vue'),
                      name: 'clubCalendar',
                      meta: {
                        // permission: 'club.update',
                        pageTitle: 'تقویم باشگاه',
                        pageTitleI18n: 'clubs.calendar.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'activities-log',
                      name: 'clubActivitiesLog',
                      component: () => import(/* webpackChunkName: "clubActivitiesLog" */ '../views/admin/booking/clubs/club/activitiesLog/clubActivitiesLog.vue'),
                      meta: {
                        permission: 'activity_logs.show',
                        pageTitle: 'لاگ های باشگاه',
                        pageTitleI18n: 'clubs.logs.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'deploy',
                      component: () => import(/* webpackChunkName: "clubDeploy" */ '../views/admin/booking/clubs/club/deploy/clubDeploy.vue'),
                      name: 'clubDeploy',
                      meta: {
                        permission: 'club.deploy',
                        pageTitle: 'دیپلوی باشگاه',
                        pageTitleI18n: 'clubs.deploy.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'requests',
                      component: () => import(/* webpackChunkName: "clubRequests" */ '../views/admin/booking/clubs/club/request/list/clubRequestList.vue'),
                      name: 'clubRequests',
                      meta: {
                        permission: 'club_request.show',
                        pageTitle: 'درخواست های باشگاه',
                        pageTitleI18n: 'clubs.requests.list.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'receives',
                      component: () => import(/* webpackChunkName: "clubReceives" */ '../views/admin/booking/clubs/club/receive/list/clubReceivesList.vue'),
                      name: 'clubReceives',
                      meta: {
                        permission: 'receive.show',
                        pageTitle: 'دریافت های باشگاه',
                        pageTitleI18n: 'clubs.receives.list.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'receives/:receiveId',
                      component: () => import(/* webpackChunkName: "clubReceive" */ '../views/admin/booking/clubs/club/receive/document/clubReceiveDocument.vue'),
                      name: 'clubReceive',
                      meta: {
                        permission: 'receive.show',
                        pageTitle: 'سند دریافت باشگاه',
                        pageTitleI18n: 'clubs.receives.document.title'
                        // rule: 'editor'
                      }
                    }
                  ]
                }
              ]
            },
            {
              path: 'sale-plan',
              component: () => import('../views/admin/booking/salePlans/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'salePlans',
                  component: () => import(/* webpackChunkName: "salePlans" */ '../views/admin/booking/salePlans/list/salePlanList.vue'),
                  meta: {
                    permission: 'sale_plan.show',
                    pageTitle: 'پلن های فروش',
                    pageTitleI18n: 'booking.salePlan.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'salePlansTrash',
                  component: () => import(/* webpackChunkName: "salePlansTrash" */ '../views/admin/booking/salePlans/trash/salePlanTrash.vue'),
                  meta: {
                    permission: 'sale_plan.delete',
                    pageTitle: 'سطل زباله پلن فروش',
                    pageTitleI18n: 'booking.salePlan.trash.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  component: () => import('../views/admin/booking/salePlans/salePlan/index.vue'),
                  children: [
                    {
                      path: '/',
                      component: () => import(/* webpackChunkName: "salePlan" */ '../views/admin/booking/salePlans/salePlan/profile/salePlanProfile.vue'),
                      name: 'salePlan',
                      meta: {
                        permission: 'sale_plan.show',
                        pageTitle: 'پلن فروش',
                        pageTitleI18n: 'booking.salePlan.profile.title',
                        layoutStatus: 'profile'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'edit',
                      component: () => import(/* webpackChunkName: "editSalePlan" */ '../views/admin/booking/salePlans/edit/editSalePlan.vue'),
                      name: 'editSalePlan',
                      meta: {
                        permission: 'sale_plan.update',
                        pageTitle: 'ویرایش پلن فروش',
                        pageTitleI18n: 'booking.salePlan.edit.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'clubs',
                      component: () => import(/* webpackChunkName: "salePlanClubs" */ '../views/admin/booking/salePlans/salePlan/clubs/salePlanClubs.vue'),
                      name: 'salePlanClubs',
                      meta: {
                        permission: 'sale_plan.update',
                        pageTitle: 'باشگاه های پلن فروش',
                        pageTitleI18n: 'booking.salePlan.clubs.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'limits',
                      component: () => import(/* webpackChunkName: "salePlanLimits" */ '../views/admin/booking/salePlans/salePlan/limits/salePlanLimits.vue'),
                      name: 'salePlanLimits',
                      meta: {
                        permission: 'sale_plan.update',
                        pageTitle: 'محدودیت های پلن فروش',
                        pageTitleI18n: 'booking.salePlan.limits.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'activities-log',
                      name: 'salePlanActivitiesLog',
                      component: () => import(/* webpackChunkName: "salePlanActivitiesLog" */ '../views/admin/booking/salePlans/salePlan/activitiesLog/salePlanActivitiesLog.vue'),
                      meta: {
                        permission: 'activity_logs.show',
                        pageTitle: 'لاگ های پلن فروش',
                        pageTitleI18n: 'booking.salePlan.logs.title'
                        // rule: 'editor'
                      }
                    }
                  ]
                }
              ]
            },
            {
              path: 'sports',
              component: () => import('../views/admin/booking/sports/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'sports',
                  component: () => import(/* webpackChunkName: "sports" */ '../views/admin/booking/sports/list/sportsList.vue'),
                  meta: {
                    permission: 'sport.show',
                    pageTitle: 'ورزش ها',
                    pageTitleI18n: 'booking.sports.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'sportsTrash',
                  component: () => import(/* webpackChunkName: "sportsTrash" */ '../views/admin/booking/sports/trash/sportTrash.vue'),
                  meta: {
                    permission: 'sport.delete',
                    pageTitle: 'سطل زباله ورزش',
                    pageTitleI18n: 'booking.sports.trash.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  component: () => import('../views/admin/booking/sports/sport/index.vue'),
                  children: [
                    {
                      path: '/',
                      component: () => import(/* webpackChunkName: "sport" */ '../views/admin/booking/sports/sport/profile/sportProfile.vue'),
                      name: 'sport',
                      meta: {
                        permission: 'sport.show',
                        pageTitle: 'ورزش',
                        pageTitleI18n: 'booking.sports.profile.title',
                        layoutStatus: 'profile'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'edit',
                      component: () => import(/* webpackChunkName: "editSport" */ '../views/admin/booking/sports/edit/editSport.vue'),
                      name: 'editSport',
                      meta: {
                        permission: 'sport.update',
                        pageTitle: 'ویرایش ورزش',
                        pageTitleI18n: 'booking.sports.edit.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'clubs',
                      component: () => import(/* webpackChunkName: "sportClubs" */ '../views/admin/booking/sports/sport/clubs/sportClubs.vue'),
                      name: 'sportClubs',
                      meta: {
                        permission: 'sport.update',
                        pageTitle: 'باشگاه های پلن فروش',
                        pageTitleI18n: 'booking.sports.clubs.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'activities-log',
                      name: 'sportActivitiesLog',
                      component: () => import(/* webpackChunkName: "sportActivitiesLog" */ '../views/admin/booking/sports/sport/activitiesLog/sportActivitiesLog.vue'),
                      meta: {
                        permission: 'activity_logs.show',
                        pageTitle: 'لاگ های پلن فروش',
                        pageTitleI18n: 'booking.sports.logs.title'
                        // rule: 'editor'
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },

        // =============================================================================
        // TREASURY PAGES LAYOUTS
        // =============================================================================
        {
          path: '/treasury',
          component: () => import('../views/admin/treasury/index.vue'),
          children: [
            {
              path: '/',
              redirect: '/error-404'
            },
            {
              path: 'banks',
              component: () => import('../views/admin/treasury/banks/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'treasuryBanks',
                  component: () => import(/* webpackChunkName: "treasuryBanks" */ '../views/admin/treasury/banks/list/banksList.vue'),
                  meta: {
                    permission: 'bank.show',
                    pageTitle: 'بانک ها',
                    pageTitleI18n: 'treasury.banks.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertTreasuryBank',
                  component: () => import(/* webpackChunkName: "insertTreasuryBank" */ '../views/admin/treasury/banks/insert/insertBank.vue'),
                  meta: {
                    permission: 'bank.create',
                    pageTitle: 'افزودن بانک',
                    pageTitleI18n: 'treasury.banks.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'treasuryBankTrash',
                  component: () => import(/* webpackChunkName: "treasuryBankTrash" */ '../views/admin/treasury/banks/trash/banksTrash.vue'),
                  meta: {
                    permission: 'bank.delete',
                    pageTitle: 'سطل زباله بانک ها',
                    pageTitleI18n: 'treasury.banks.trash.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  component: () => import('../views/admin/treasury/banks/bank/index.vue'),
                  children: [
                    {
                      path: '/',
                      name: 'treasuryBank',
                      component: () => import(/* webpackChunkName: "treasuryBank" */ '../views/admin/treasury/banks/bank/menu/bankMenu.vue'),
                      meta: {
                        permission: 'bank.show',
                        pageTitle: 'بانک',
                        pageTitleI18n: 'treasury.banks.bank.title',
                        layoutStatus: 'profile'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'edit',
                      name: 'editTreasuryBank',
                      component: () => import(/* webpackChunkName: "editTreasuryBank" */ '../views/admin/treasury/banks/bank/edit/editBank.vue'),
                      meta: {
                        permission: 'bank.update',
                        pageTitle: 'ویرایش بانک',
                        pageTitleI18n: 'treasury.banks.edit.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'transactions',
                      name: 'transactionsTreasuryBank',
                      component: () => import(/* webpackChunkName: "transactionsTreasuryBank" */ '../views/admin/treasury/banks/bank/transactions/bankTransactions.vue'),
                      meta: {
                        permission: 'bank.show',
                        pageTitle: '',
                        pageTitleI18n: 'treasury.banks.transactions.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'activities-log',
                      name: 'BankActivitiesLog',
                      component: () => import(/* webpackChunkName: "bankActivitiesLog" */ '../views/admin/treasury/banks/bank/activitiesLog/bankActivitiesLog.vue'),
                      meta: {
                        permission: 'activity_logs.show',
                        pageTitle: 'لاگ های بانک',
                        pageTitleI18n: 'treasury.banks.logs.title'
                        // rule: 'editor'
                      }
                    }
                  ]
                }
              ]
            },
            {
              path: 'payment-gateways',
              component: () => import('../views/admin/treasury/paymentGateways/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'paymentGateways',
                  component: () => import(/* webpackChunkName: "paymentGateways" */ '../views/admin/treasury/paymentGateways/list/paymentGateways.vue'),
                  meta: {
                    permission: 'payment_gateway.show',
                    pageTitle: 'درگاه های پرداخت',
                    pageTitleI18n: 'treasury.paymentGateways.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertPaymentGateway',
                  component: () => import(/* webpackChunkName: "insertPaymentGateway" */ '../views/admin/treasury/paymentGateways/insert/insertPaymentGateway.vue'),
                  meta: {
                    permission: 'payment_gateway.create',
                    pageTitle: 'افزودن درگاه پرداخت',
                    pageTitleI18n: 'treasury.paymentGateways.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'trashedPaymentGateways',
                  component: () => import(/* webpackChunkName: "trashedPaymentGateways" */ '../views/admin/treasury/paymentGateways/trash/trashedPaymentGateways.vue'),
                  meta: {
                    permission: 'payment_gateway.delete',
                    pageTitle: 'سطل زباله درگاه های پرداخت',
                    pageTitleI18n: 'treasury.paymentGateways.trash.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  component: () => import('../views/admin/treasury/paymentGateways/paymentGateway/index.vue'),
                  children: [
                    {
                      path: '/',
                      name: 'paymentGateway',
                      component: () => import(/* webpackChunkName: "paymentGateway" */ '../views/admin/treasury/paymentGateways/paymentGateway/profile/paymentGateway.vue'),
                      meta: {
                        permission: 'payment_gateway.show',
                        pageTitle: 'درگاه پرداخت',
                        pageTitleI18n: 'treasury.paymentGateways.paymentGateway.title',
                        layoutStatus: 'profile'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'edit',
                      name: 'editPaymentGateway',
                      component: () => import(/* webpackChunkName: "editPaymentGateway" */ '../views/admin/treasury/paymentGateways/paymentGateway/edit/editPaymentGateway.vue'),
                      meta: {
                        permission: 'payment_gateway.update',
                        pageTitle: 'ویرایش درگاه پرداخت',
                        pageTitleI18n: 'treasury.paymentGateways.edit.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'transactions',
                      name: 'transactionsPaymentGateway',
                      component: () => import(/* webpackChunkName: "transactionsPaymentGateway" */ '../views/admin/treasury/paymentGateways/paymentGateway/transactions/paymentGatewayTransactions.vue'),
                      meta: {
                        permission: 'payment_gateway.show',
                        pageTitle: 'گردش مالی درگاه پرداخت',
                        pageTitleI18n: 'treasury.paymentGateways.transactions.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'receive',
                      name: 'receivePaymentGateway',
                      component: () => import(/* webpackChunkName: "transactionsPaymentGateway" */ '../views/admin/treasury/paymentGateways/paymentGateway/receive/receivedPaymentGatewayBalance.vue'),
                      meta: {
                        permission: 'payment_gateway.show',
                        pageTitle: 'اعلام وصول درگاه پرداخت',
                        pageTitleI18n: 'treasury.paymentGateways.receive.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'activities-log',
                      name: 'activitiesLogPaymentGateway',
                      component: () => import(/* webpackChunkName: "paymentGatewayActivitiesLog" */ '../views/admin/treasury/paymentGateways/paymentGateway/activitiesLog/paymentGatewayActivitiesLog.vue'),
                      meta: {
                        permission: 'activity_logs.show',
                        pageTitle: 'لاگ های درگاه پرداخت',
                        pageTitleI18n: 'treasury.paymentGateways.logs.title'
                        // rule: 'editor'
                      }
                    }
                  ]
                }
              ]
            },
            {
              path: 'cash_boxes',
              component: () => import('../views/admin/treasury/cashBoxes/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'treasuryCashBoxes',
                  component: () => import(/* webpackChunkName: "treasuryCashBoxes" */ '../views/admin/treasury/cashBoxes/list/cashBoxList.vue'),
                  meta: {
                    permission: 'cash.show',
                    pageTitle: 'صندوق ها',
                    pageTitleI18n: 'treasury.cashBoxes.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertTreasuryCashBox',
                  component: () => import(/* webpackChunkName: "insertTreasuryCashBox" */ '../views/admin/treasury/cashBoxes/insert/insertCashBox.vue'),
                  meta: {
                    permission: 'cash.create',
                    pageTitle: 'افزودن صندوق',
                    pageTitleI18n: 'treasury.cashBoxes.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'trash',
                  name: 'treasuryCashBoxTrash',
                  component: () => import(/* webpackChunkName: "treasuryCashBoxTrash" */ '../views/admin/treasury/cashBoxes/trash/cashBoxesTrash.vue'),
                  meta: {
                    permission: 'cash.delete',
                    pageTitle: 'سطل زباله صندوق ها',
                    pageTitleI18n: 'treasury.cashBoxes.trash.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  component: () => import('../views/admin/treasury/cashBoxes/cashBox/index.vue'),
                  children: [
                    {
                      path: '/',
                      name: 'treasuryCashBox',
                      component: () => import(/* webpackChunkName: "treasuryCashBox" */ '../views/admin/treasury/cashBoxes/cashBox/menu/cashBoxMenu.vue'),
                      meta: {
                        permission: 'cash.show',
                        pageTitle: 'صندوق',
                        pageTitleI18n: 'treasury.cashBoxes.cashBox.title',
                        layoutStatus: 'profile'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'edit',
                      name: 'editTreasuryCashBox',
                      component: () => import(/* webpackChunkName: "editTreasuryCashBox" */ '../views/admin/treasury/cashBoxes/cashBox/edit/editCashBox.vue'),
                      meta: {
                        permission: 'cash.update',
                        pageTitle: 'ویرایش صندوق',
                        pageTitleI18n: 'treasury.cashBoxes.edit.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'transactions',
                      name: 'transactionsTreasuryCashBox',
                      component: () => import(/* webpackChunkName: "transactionsTreasuryCashBox" */ '../views/admin/treasury/cashBoxes/cashBox/transactions/cashBoxTransactions.vue'),
                      meta: {
                        permission: 'cash.show',
                        pageTitle: 'گزارشات صندوق',
                        pageTitleI18n: 'treasury.cashBoxes.transactions.title'
                        // rule: 'editor'
                      }
                    },
                    {
                      path: 'activities-log',
                      name: 'activitiesLogCashBox',
                      component: () => import(/* webpackChunkName: "cashBoxActivitiesLog" */ '../views/admin/treasury/cashBoxes/cashBox/activitiesLog/cashBoxActivitiesLog.vue'),
                      meta: {
                        permission: 'activity_logs.show',
                        pageTitle: 'لاگ های صندوق',
                        pageTitleI18n: 'treasury.cashBoxes.logs.title'
                        // rule: 'editor'
                      }
                    }
                  ]
                }
              ]
            },
            {
              path: 'receive',
              component: () => import('../views/admin/treasury/receive/index.vue'),
              children: [
                {
                  path: '',
                  name: 'treasuryReceive',
                  component: () => import(/* webpackChunkName: "treasuryReceive" */ '../views/admin/treasury/receive/list/receivesList.vue'),
                  meta: {
                    pageTitle: 'دریافت',
                    pageTitleI18n: 'treasury.receive.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertTreasuryReceive',
                  component: () => import(/* webpackChunkName: "insertTreasuryReceive" */ '../views/admin/treasury/receive/insert/insertReceive.vue'),
                  meta: {
                    pageTitle: 'دریافت',
                    pageTitleI18n: 'treasury.receive.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'doc-:id',
                  name: 'treasuryReceiveDocument',
                  component: () => import(/* webpackChunkName: "treasuryReceiveDocument" */ '../views/admin/treasury/receive/document/receiveDocument.vue'),
                  meta: {
                    pageTitle: 'سند دریافت',
                    pageTitleI18n: 'treasury.receive.document.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'doc-:id/activities-log',
                  name: 'receiveActivitiesLog',
                  component: () => import(/* webpackChunkName: "receiveActivitiesLog" */ '../views/admin/treasury/receive/activitiesLog/receiveActivitiesLog.vue'),
                  meta: {
                    permission: 'activity_logs.show',
                    pageTitle: 'لاگ های دریافت',
                    pageTitleI18n: 'treasury.receive.logs.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'cost',
              component: () => import('../views/admin/treasury/cost/index.vue'),
              children: [
                {
                  path: '',
                  name: 'treasuryCost',
                  component: () => import(/* webpackChunkName: "treasuryCost" */ '../views/admin/treasury/cost/list/costsList.vue'),
                  meta: {
                    permission: 'cost.show',
                    pageTitle: 'هزینه',
                    pageTitleI18n: 'treasury.cost.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertTreasuryCost',
                  component: () => import(/* webpackChunkName: "insertTreasuryCost" */ '../views/admin/treasury/cost/insert/insertCost.vue'),
                  meta: {
                    permission: 'cost.create',
                    pageTitle: 'هزینه جدید',
                    pageTitleI18n: 'treasury.cost.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'doc-:id',
                  name: 'treasuryCostDocument',
                  component: () => import(/* webpackChunkName: "treasuryCostDocument" */ '../views/admin/treasury/cost/document/costDocument.vue'),
                  meta: {
                    permission: 'cost.show',
                    pageTitle: 'سند هزینه',
                    pageTitleI18n: 'treasury.cost.document.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'doc-:id/activities-log',
                  name: 'costActivitiesLog',
                  component: () => import(/* webpackChunkName: "costActivitiesLog" */ '../views/admin/treasury/cost/activitiesLog/costActivitiesLog.vue'),
                  meta: {
                    permission: 'activity_logs.show',
                    pageTitle: 'لاگ های هزینه',
                    pageTitleI18n: 'treasury.cost.logs.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'payment',
              component: () => import('../views/admin/treasury/payment/index.vue'),
              children: [
                {
                  path: '',
                  name: 'treasuryPayment',
                  component: () => import(/* webpackChunkName: "treasuryPayment" */ '../views/admin/treasury/payment/list/paymentsList.vue'),
                  meta: {
                    pageTitle: 'پرداخت',
                    pageTitleI18n: 'treasury.payment.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertTreasuryPayment',
                  component: () => import(/* webpackChunkName: "insertTreasuryPayment" */ '../views/admin/treasury/payment/insert/insertPayment.vue'),
                  meta: {
                    pageTitle: 'پرداخت جدید',
                    pageTitleI18n: 'treasury.payment.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'doc-:id',
                  name: 'treasuryPaymentDocument',
                  component: () => import(/* webpackChunkName: "treasuryPaymentDocument" */ '../views/admin/treasury/payment/document/paymentDocument.vue'),
                  meta: {
                    pageTitle: 'سند پرداخت',
                    pageTitleI18n: 'treasury.payment.document.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'doc-:id/activities-log',
                  name: 'paymentActivitiesLog',
                  component: () => import(/* webpackChunkName: "paymentActivitiesLog" */ '../views/admin/treasury/payment/activitiesLog/paymentActivitiesLog.vue'),
                  meta: {
                    permission: 'activity_logs.show',
                    pageTitle: 'لاگ های پرداخت',
                    pageTitleI18n: 'treasury.payment.logs.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'internal-funds-transfers',
              component: () => import('../views/admin/treasury/InternalFundsTransfer/index.vue'),
              children: [
                {
                  path: '',
                  name: 'treasuryInternalFundsTransfers',
                  component: () => import(/* webpackChunkName: "treasuryInternalFundsTransfers" */ '../views/admin/treasury/InternalFundsTransfer/list/internalFundsTransfersList.vue'),
                  meta: {
                    pageTitle: 'انتقالی خزانه',
                    pageTitleI18n: 'treasury.internalFundsTransfer.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'doc-:id',
                  name: 'treasuryInternalFundsTransferDocument',
                  component: () => import(/* webpackChunkName: "treasuryInternalFundsTransferDocument" */ '../views/admin/treasury/InternalFundsTransfer/document/internalFundsTransferDocument.vue'),
                  meta: {
                    pageTitle: 'انتقالی خزانه',
                    pageTitleI18n: 'treasury.internalFundsTransfer.document.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertTreasuryInternalFundsTransfers',
                  component: () => import(/* webpackChunkName: "insertTreasuryInternalFundsTransfers" */ '../views/admin/treasury/InternalFundsTransfer/insert/insertInternalFundsTransfer.vue'),
                  meta: {
                    pageTitle: 'انتقالی خزانه جدید',
                    pageTitleI18n: 'treasury.internalFundsTransfer.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'doc-:id/activities-log',
                  name: 'internalFundsTransfersActivitiesLog',
                  component: () => import(/* webpackChunkName: "internalFundActivitiesLog" */ '../views/admin/treasury/InternalFundsTransfer/activitiesLog/internalFundActivitiesLog.vue'),
                  meta: {
                    permission: 'activity_logs.show',
                    pageTitle: 'لاگ های انتقالی خرانه',
                    pageTitleI18n: 'treasury.internalFundsTransfer.logs.title'
                    // rule: 'editor'
                  }
                }
              ]
            }
          ]
        },

        // =============================================================================
        // ACCOUNTANCY PAGES LAYOUTS
        // =============================================================================
        {
          path: '/accountancy',
          component: () => import('../views/admin/accountancy/index.vue'),
          children: [
            {
              path: 'types',
              name: 'documentsType',
              component: () => import(/* webpackChunkName: "documentsType" */ '../views/admin/accountancy/types/documentsType.vue'),
              meta: {
                permission: 'account.access',
                pageTitle: '',
                pageTitleI18n: 'accountancy.types.title'
                // rule: 'editor'
              }
            },
            {
              path: 'documents',
              component: () => import('../views/admin/accountancy/documents/index.vue'),
              children: [
                {
                  path: '',
                  name: 'documents',
                  component: () => import(/* webpackChunkName: "documents" */ '../views/admin/accountancy/documents/list/documentsList.vue'),
                  meta: {
                    permission: 'document.access',
                    pageTitle: '',
                    pageTitleI18n: 'accountancy.documents.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertDocument',
                  component: () => import(/* webpackChunkName: "insertDocument" */ '../views/admin/accountancy/documents/insert/insertDocument.vue'),
                  meta: {
                    permission: 'document.access',
                    pageTitle: 'افزودن سند دریافت/پرداخت',
                    pageTitleI18n: 'accountancy.documents.insert.dynamicTitle'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'doc-:id',
                  name: 'document',
                  component: () => import(/* webpackChunkName: "document" */ '../views/admin/accountancy/documents/document/document.vue'),
                  meta: {
                    permission: 'document.access',
                    pageTitle: '',
                    pageTitleI18n: 'accountancy.documents.document.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'opening-balance',
              name: 'openingBalance',
              component: () => import(/* webpackChunkName: "openingBalance" */ '../views/admin/accountancy/openingBalance/index.vue'),
              meta: {
                permission: 'opening.access',
                pageTitle: '',
                pageTitleI18n: 'accountancy.openingBalance.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // TICKETS PAGES LAYOUTS
        // =============================================================================
        {
          path: '/ticketing',
          component: () => import('../views/admin/tickets/index.vue'),
          children: [
            {
              path: 'organizational',
              component: () => import('../views/admin/tickets/organizationTicket/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'organizationTickets',
                  component: () => import(/* webpackChunkName: "organizationTickets" */ '../views/admin/tickets/organizationTicket/list/ticketsOrganizationList.vue'),
                  meta: {
                    permission: 'organizational_ticket.show',
                    pageTitle: 'تیکت ها',
                    pageTitleI18n: 'tickets.organization.list.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: 'insert',
                  name: 'insertOrganizationTicket',
                  component: () => import(/* webpackChunkName: "insertOrganizationTicket" */ '../views/admin/tickets/organizationTicket/insert/insertOrganizationTicket.vue'),
                  meta: {
                    permission: 'organizational_ticket.create',
                    pageTitle: 'افزودن تیکت جدید',
                    pageTitleI18n: 'tickets.organization.insert.title'
                    // rule: 'editor'
                  }
                },
                {
                  path: ':id',
                  name: 'organizationTicketDetail',
                  component: () => import(/* webpackChunkName: "organizationTicketDetail" */ '../views/admin/tickets/organizationTicket/profile/ticketOrganizationDetail.vue'),
                  meta: {
                    permission: 'organizational_ticket.show',
                    pageTitle: '',
                    pageTitleI18n: 'tickets.organization.detail.title'
                    // rule: 'editor'
                  }
                }
              ]
            }
          ]
        },

        // =============================================================================
        // SETTING PAGES LAYOUTS
        // =============================================================================
        {
          path: '/settings',
          component: () => import('../views/admin/settings/index.vue'),
          children: [
            {
              path: '/',
              name: 'Settings',
              component: () => import(/* webpackChunkName: "Settings" */ '../views/admin/settings/setting/settingMenu.vue'),
              meta: {
                pageTitle: 'تنظیمات',
                pageTitleI18n: 'setting.title',
                layoutStatus: 'profile'
                // rule: 'editor'
              }
            },
            {
              path: 'company-info',
              name: 'companyInfoSetting',
              component: () => import(/* webpackChunkName: "companyInfoSetting" */ '../views/admin/settings/companyInfo/companyInfoSetting.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'setting.companyInfo.title'
                // rule: 'editor'
              }
            },
            {
              path: 'backup',
              name: 'backup',
              component: () => import(/* webpackChunkName: "backup" */ '../views/admin/settings/backup/backup.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'backup.title'
                // rule: 'editor'
              }
            },
            {
              path: 'roles',
              component: () => import(/* webpackChunkName: "access" */ '../views/admin/settings/access/index.vue'),
              children: [
                {
                  path: '/',
                  name: 'accessGroupsList',
                  component: () => import(/* webpackChunkName: "accessGroupsList" */ '../views/admin/settings/access/list/accessGroupsList.vue'),
                  meta: {
                    permission: 'role.show',
                    pageTitle: '',
                    pageTitleI18n: 'setting.accessGroup.title'
                    // rule: 'editor'
                  }
                }
              ]
            },
            {
              path: 'languages',
              name: 'languagesSetting',
              component: () => import(/* webpackChunkName: "languagesSetting" */ '../views/admin/settings/locale/LocaleSetting.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'setting.Locale.title',
                permission: 'setting.show'
                // rule: 'editor'
              }
            },
            {
              path: 'users',
              name: 'usersSetting',
              component: () => import(/* webpackChunkName: "usersSetting" */ '../views/admin/settings/users/usersSetting.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'setting.users.title'
                // rule: 'editor'
              }
            },
            {
              path: 'security',
              name: 'securitySetting',
              component: () => import(/* webpackChunkName: "securitySetting" */ '../views/admin/settings/security/securitySetting.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'setting.security.title',
                permission: 'setting.show'
                // rule: 'editor'
              }
            },
            {
              path: 'customizing',
              name: 'customizeSetting',
              component: () => import(/* webpackChunkName: "customizeSetting" */ '../views/admin/settings/customize/customizeSetting.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'setting.customize.title',
                permission: 'setting.show'
                // rule: 'editor'
              }
            },
            {
              path: 'deploy',
              name: 'deploySetting',
              component: () => import(/* webpackChunkName: "deploySetting" */ '../views/admin/settings/deploy/deploySetting.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'setting.deploy.title',
                permission: 'setting.advance.show'
                // rule: 'editor'
              }
            },
            {
              path: 'financial',
              name: 'financialSetting',
              component: () => import(/* webpackChunkName: "financialSetting" */ '../views/admin/settings/financial/FinancialSetting.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'setting.financial.title',
                permission: 'setting.show'
                // rule: 'editor'
              }
            },
            {
              path: 'financial-year',
              name: 'financialYearSetting',
              component: () => import(/* webpackChunkName: "financialYearSetting" */ '../views/admin/settings/financialYear/list/financialYearList.vue'),
              meta: {
                permission: 'financial_year.show',
                pageTitle: 'تنظیمات سال مالی',
                pageTitleI18n: 'setting.financialYear.title'
                // rule: 'editor'
              }
            },
            {
              path: 'print',
              name: 'printSetting',
              component: () => import(/* webpackChunkName: "shippingSetting" */ '../views/admin/settings/print/PrintSetting.vue'),
              meta: {
                permission: 'setting.show',
                pageTitle: 'تنظیمات پرینت',
                pageTitleI18n: 'setting.print.title'
                // rule: 'editor'
              }
            },
            {
              path: 'message',
              name: 'messageSetting',
              component: () => import(/* webpackChunkName: "messageSetting" */ '../views/admin/settings/message/messageSetting.vue'),
              meta: {
                permission: 'setting.show',
                pageTitle: 'تنظیمات پیامک',
                pageTitleI18n: 'setting.message.title'
                // rule: 'editor'
              }
            },
            {
              path: 'advanced',
              name: 'advancedSetting',
              component: () => import(/* webpackChunkName: "advancedSetting" */ '../views/admin/settings/advanced/advancedSetting.vue'),
              meta: {
                pageTitle: 'تنظیمات پیشرفته',
                pageTitleI18n: ''
                // rule: 'editor'
              }
            },
            {
              path: 'reset',
              name: 'resetSetting',
              component: () => import(/* webpackChunkName: "resetSetting" */ '../views/admin/settings/reset/ResetSetting.vue'),
              meta: {
                permission: 'setting.reset_application',
                pageTitle: 'تنظیمات ریست',
                pageTitleI18n: 'setting.reset.title'
                // rule: 'editor'
              }
            }
          ]
        },

        // =============================================================================
        // REPORTS PAGES LAYOUTS
        // =============================================================================
        {
          path: '/reports',
          component: () => import('../views/admin/reports/index'),
          children: [
            {
              path: 'activities-log',
              name: 'activitiesLog',
              component: () => import(/* webpackChunkName: "activitiesLog" */ '../views/admin/reports/activitiesLog/usersActivitiesLog.vue'),
              meta: {
                permission: 'activity_logs.show',
                pageTitle: '',
                pageTitleI18n: 'reports.logs.list.title'
                // rule: 'editor'
              }
            },
            {
              path: 'users-total-sales',
              name: 'usersTotalSalesReport',
              component: () => import(/* webpackChunkName: "usersTotalSalesReport" */ '../views/admin/reports/usersTotalSales/usersTotalSalesReport.vue'),
              meta: {
                pageTitle: '',
                pageTitleI18n: 'reports.usersTotalSales.title'
                // rule: 'editor'
              }
            }
          ]
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/login',
          name: 'page-login',
          component: () => import(/* webpackChunkName: "page-login" */ '@/views/pages/Login.vue')
        },
        {
          path: '/error-404',
          name: 'page-error-404',
          component: () => import(/* webpackChunkName: "page-error-404" */ '@/views/pages/Error404.vue')
        },
        {
          path: '/treasury/payment/:id/print',
          name: 'print-payment-page',
          component: () => import(/* webpackChunkName: "print-payment-page" */ '@/views/admin/accountancy/documents/print/PrintPayment.vue')
        },
        {
          path: '/treasury/receive/:id/print',
          name: 'print-receive-page',
          component: () => import(/* webpackChunkName: "print-receive-page" */ '@/views/admin/accountancy/documents/print/PrintReceive.vue')
        },
        {
          path: '/treasury/internal-funds-transfers/:id/print',
          name: 'print-internal-funds-transfer-page',
          component: () => import(/* webpackChunkName: "print-internal-funds-transfer-page" */ '@/views/admin/accountancy/documents/print/PrintInternalFundsTransfer.vue')
        },
        {
          path: '/treasury/cost/:id/print',
          name: 'print-cost-page',
          component: () => import(/* webpackChunkName: "print-cost-page" */ '@/views/admin/accountancy/documents/print/PrintCost.vue')
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/error-404'
    }
  ]
})

router.afterEach(afterEach)

export default router

router.beforeEach(beforeEach)
